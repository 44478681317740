<template>
  <div class="warehouse-stock-site-manage">
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="itemClick(item)">
            <div class="content">
              <div class="info">
                <div class="name">
                  <b>{{ item.name }}</b>
                </div>
                <div class="name">最大容纳数: {{ item.maxCapacity }}</div>
                <div class="name">货位编号: {{ item.code }}</div>
              </div>

              <div class="action"
                   @click.stop="del(item.id)">
                <van-icon name="cross" />
              </div>
            </div>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-popup class="dialog"
                 v-model="showDialog"
                 :style="{ width: '80%' }">
        <div style="padding:10px;">
          <van-form @submit="onSubmit">
            <van-field label="货位名称"
                       v-model="form.name"
                       placeholder="请输入"
                       required
                       :rules="[{ required: true, message: '货位名称不能为空' }]"></van-field>
            <van-field label="最大容纳数"
                       v-model="form.maxCapacity"
                       placeholder="请输入"
                       required
                       type="digit"
                       :rules="[{ required: true, message: '最大容纳数不能为空' }]"></van-field>
            <van-field label="货位编号"
                       v-model="form.code"
                       placeholder="请输入"
                       required
                       name="validator"
                       maxlength="15"
                       :rules="[{ required: true, message: '货位编号不能为空' },{validator, message: '请输入数字或字母' }]"></van-field>
            <div class="action">
              <van-button class="btn"
                          type="info"
                          round
                          size="small">确定</van-button>
              <van-button class="btn"
                          type="default"
                          round
                          size="small"
                          native-type="button"
                          @click="dialogCancel">取消</van-button>
            </div>
          </van-form>
        </div>
      </van-popup>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <div class="footer">
      <van-button class="add-btn"
                  type="info"
                  round
                  @click="add">新增货位</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/warehouse/warehouseStockSiteManage.less";
import Filter from "ldap-filters";
import { SUCCESS_CODE } from "@/apis/notification";
import { assign, cloneDeep, findIndex } from "lodash-es";
export default {
  name: "WarehouseStockSiteManage",
  data() {
    return {
      form: {
        name: "",
        maxCapacity: "",
        code: ""
      },
      warehouseTypeColumns: [],
      showWarehouseType: false,
      warehouseAdminColumns: [],
      showAdmin: false,
      organizationColumns: [],
      showOrgs: false,
      list: [],
      listStatus: {
        loading: true,
        finished: false
      },
      showDialog: false,
      statusColumns: [
        { label: "启用", value: "ENABLE" },
        { label: "禁用", value: "DISABLE" },
        { label: "删除", value: "DELETED" }
      ],
      showStatus: false,
      pager: {
        pageNumber: 0,
        pageSize: 10
      }
    };
  },
  computed: {},
  methods: {
    async getWarehouseStockSiteList(sign = false) {
      let params = {
        pageNumber: this.pager.pageNumber,
        pageSize: this.pager.pageSize,
        sort: encodeURI(`[{"fieldName":"code","direction":"asc"}]`)
      };
      params.query = this.getQuery();
      let ret = await this.$api.getWarehouseStockSiteList({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery() {
      let filters = [];
      filters.push(Filter.attribute("status").equalTo("SUCCESS"));
      filters.push(Filter.attribute("warehouseId").equalTo(this.$route.params.id));
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    addWarehouseStockSite() {
      let { name, maxCapacity, code } = this.form;
      let params = {
        name,
        maxCapacity,
        warehouseId: this.$route.params.id,
        code
      };
      return this.$api.addWarehouseStockSite({
        params,
        placeholder: {
          id: this.$route.params.id
        }
      });
    },
    setWarehouseStockSite() {
      let { id, name, maxCapacity, code } = this.form;
      let params = {
        id,
        name,
        maxCapacity,
        warehouseId: this.$route.params.id,
        code
      };
      return this.$api.setWarehouseStockSite({
        params,
        placeholder: {
          id,
          warehouseId: this.$route.params.id
        }
      });
    },
    deleteWarehouseStockSite(ids, warehouseId) {
      return this.$api.deleteWarehouseStockSite({
        placeholder: {
          ids: ids.toString(),
          warehouseId
        }
      });
    },
    itemClick(item) {
      this.showDialog = true;
      assign(this.form, item);
    },
    async onSubmit() {
      let ret;
      if (this.form.id) {
        // 编辑
        ret = await this.setWarehouseStockSite();
      } else {
        // 新增
        ret = await this.addWarehouseStockSite();
      }
      if (ret.code != SUCCESS_CODE) return;
      this.getWarehouseStockSiteList();
      this.showDialog = false;
      this.form = cloneDeep(this.formCopy);
    },
    add() {
      this.form = cloneDeep(this.formCopy);
      this.showDialog = true;
    },
    del(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定删除?"
        })
        .then(async () => {
          let ret = await this.deleteWarehouseStockSite([id], this.$route.params.id);
          if (ret.code != SUCCESS_CODE) return;
          this.getWarehouseStockSiteList();
        })
        .catch(() => {});
    },
    dialogCancel() {
      this.showDialog = false;
      this.form = cloneDeep(this.formCopy);
    },
    onLoad() {
      this.getWarehouseStockSiteList(true);
    },
    validator(val) {
      return /^[a-zA-Z0-9]*$/.test(val);
    }
  },
  created() {
    this.formCopy = cloneDeep(this.form);
    this.getWarehouseStockSiteList();
    // this.getAdminList();
    // this.getOrganizationList();
    // this.getWarehouseList();
    // this.getWarehouseTypeList();
  }
};
</script>

<style></style>
